import React from 'react'
import { blogger, english, relationship } from '../utils/categoryCards'
import { Layout, HorizontalList } from '../components'

import './categories.scss'

const Categories = () => {
  return (
    <Layout className="categories-page" centerContentVertically>
      <div className="section-inner vertical-indent--m">
        <div className="categories-page__category-wrapper">
          <h2>Вопросы в стиле популярных ведущих и блогеров</h2>
          <HorizontalList title="Вопросы в стиле популярных ведущих и блогеров">
            {blogger.dud}
            {blogger.pozner}
          </HorizontalList>
        </div>
        <div className="categories-page__category-wrapper">
          <h2>Вопросы про отношения</h2>
          <HorizontalList>
            {relationship.firstDate}
            {relationship.aboutPartner}
            {relationship.aboutLove}
            {relationship.aboutMarriage}
          </HorizontalList>
        </div>
        <div className="categories-page__category-wrapper">
          <h2>Вопросы на английском языке</h2>
          <HorizontalList>{english.sparkFunEn}</HorizontalList>
        </div>
      </div>
    </Layout>
  )
}

export default Categories
